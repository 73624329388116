.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  background-color: #191919;
}
.home-container01 {
  width: 50%;
  height: 900px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-video {
  width: 450px;
  height: 800px;
}
.home-container02 {
  width: 50%;
  height: 900px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text {
  color: #f7f7f7;
}
.home-text01 {
  color: #f7f7f7;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.home-text14 {
  color: rgb(247, 247, 247);
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-container04 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image {
  flex: 1;
  width: 100px;
  height: 178px;
  align-self: center;
  object-fit: cover;
}
.home-container05 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image1 {
  flex: 1;
  width: 100px;
  height: 178px;
  align-self: center;
  object-fit: cover;
}
.home-container06 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image2 {
  flex: 1;
  width: 100px;
  height: 178px;
  align-self: center;
  object-fit: cover;
}
.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-container08 {
  flex: 0 0 auto;
  width: 200px;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text20 {
  color: var(--dl-color-gray-mgrey);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-container09 {
  flex: 0 0 auto;
  width: 200px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text35 {
  color: var(--dl-color-gray-mgrey);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-container10 {
  flex: 0 0 auto;
  width: 200px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text50 {
  color: var(--dl-color-gray-mgrey);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text65 {
  color: rgb(247, 247, 247);
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.home-text69 {
  font-size: 10px;
}
